import request from '@/utils/http';

/**
 * 会员列表
 * @param payload
 */
export function user_list(payload) {
    return request({ url: '/User/lists', method: 'get', params: payload })
}

/**
 * 添加会员
 * @param payload
 */
export function user_create(payload) {
    return request({ url: '/User/create', method: 'post', data: payload })
}

/**
 * 更新会员
 * @param payload
 */
export function user_update(payload) {
    return request({ url: '/User/update', method: 'post', data: payload })
}

/**
 * 删除会员
 * @param payload
 */
export function user_del(payload) {
    return request({ url: '/User/delete/' + payload.id, method: 'delete', data: payload })
}

/**
 * 会员等级列表
 * @param payload
 */
export function user_level_list(payload) {
    return request({ url: '/UserLevel/lists', method: 'get', params: payload })
}

/**
 * 会员等级列表(所有)
 * @param payload
 */
export function user_level_all(payload) {
    return request({ url: '/UserLevel/listsAll', method: 'get', params: payload })
}

/**
 * 添加会员等级
 * @param payload
 */
export function user_level_create(payload) {
    return request({ url: '/UserLevel/create', method: 'post', data: payload })
}

/**
 * 更新会员等级
 * @param payload
 */
export function user_level_update(payload) {
    return request({ url: '/UserLevel/update', method: 'post', data: payload })
}

/**
 * 删除会员等级
 * @param payload
 */
export function user_level_del(payload) {
    return request({ url: '/UserLevel/delete/' + payload.id, method: 'delete', data: payload })
}

/**
 * 会员标签列表
 * @param payload
 */
export function user_label_list(payload) {
    return request({ url: '/UserLabel/lists', method: 'get', params: payload })
}

/**
 * 添加会员标签
 * @param payload
 */
export function user_label_create(payload) {
    return request({ url: '/UserLabel/create', method: 'post', data: payload })
}

/**
 * 更新会员标签
 * @param payload
 */
export function user_label_update(payload) {
    return request({ url: '/UserLabel/update', method: 'post', data: payload })
}

/**
 * 删除会员标签
 * @param payload
 */
export function user_label_del(payload) {
    return request({ url: '/UserLabel/delete/' + payload.id, method: 'delete', data: payload })
}

/**
 * 会员标签列表(所有)
 * @param payload
 */
export function user_label_all(payload) {
    return request({ url: '/UserLabel/listsAll', method: 'get', params: payload })
}

/**
 * 会员群体列表
 * @param payload
 */
export function user_cluster_list(payload) {
    return request({ url: '/UserCluster/lists', method: 'get', params: payload })
}

/**
 * 添加会员群体
 * @param payload
 */
export function user_cluster_create(payload) {
    return request({ url: '/UserCluster/create', method: 'post', data: payload })
}

/**
 * 更新会员群体
 * @param payload
 */
export function user_cluster_update(payload) {
    return request({ url: '/UserCluster/update', method: 'post', data: payload })
}

/**
 * 删除会员群体
 * @param payload
 */
export function user_cluster_del(payload) {
    return request({ url: '/UserCluster/delete/' + payload.id, method: 'delete', data: payload })
}
/**
* 会员导入记录
* @param payload
*/
export function user_import_record(payload) {
    return request({ url: '/UserImport/record', method: 'get', params: payload })
}
/**
 * 会员导入
 * @param payload
 */
export let upload = process.env.VUE_APP_API_BASE_URL + '/UserImport/member';

/**
* 会员导入模版下载
* @param payload
*/
export function user_import_file(payload) {
    return request({ url: '/UserImport/downloadMemberFile', method: 'get', params: payload })
}